<template>
  <div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
      <b-row>
        <b-col>
          <h3>TURNOS</h3>
          <p>
            Selecciona los turnos de los que requieras el reporte y presiona el boton ver en pantalla.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" md="4" sm="6" class="my-1">
          <label for="example-datepicker">Sucursal</label>
          <b-form-select v-model="sucursal_id" value-field="id" text-field="nombre"
            :options="sucursales"></b-form-select>
        </b-col>
        <b-col lg="3" md="4" sm="6" class="my-1">
          <label for="example-datepicker">Fecha Inicial</label>
          <b-form-datepicker v-model="initial_date" class="mb-2"></b-form-datepicker>
        </b-col>
        <b-col lg="3" md="4" sm="6" class="my-1">
          <label for="example-datepicker">Fecha Final</label>
          <b-form-datepicker v-model="end_date" class="mb-2"></b-form-datepicker>
        </b-col>
        <b-col lg="2" md="4" sm="6" class="my-1">
          <b-button variant="success" @click="getActualTurnos">Buscar</b-button>
        </b-col>
        <b-col lg="3" md="4" sm="6" class="my-1">
          <modal-shift-info :shifts="selectedCheck" :sucursal_id="sucursal_id"></modal-shift-info>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table responsive :items="turnos" size="sm" :fields="turnosFields" :per-page="byPage" :current-page="page">
            <template #cell(select)="data">
              <b-form-checkbox v-model="selectedCheck" :value="data.item.id"></b-form-checkbox>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination v-model="page" :total-rows="turnos.length" :per-page="byPage" aria-controls="my-table">
          </b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import ModalShiftInfo from "../components/ModalShiftInfo.vue"
import axios from "axios";

export default {
  name: "Turnos",
  components: {
    Nav, ModalShiftInfo
  },

  data() {
    return {
      sucursal_id: null,
      initial_date: "",
      end_date: "",
      byPage: 15,
      page: 1,
      selectedCheck: [],
      turnos: [],
      sendEmail: false,
      sucursales: [],
      turnosFields: [
        { key: "select", label: "" },
        { key: "id", label: "ID" },
        { key: "open_date", label: "Apertura" },
        { key: "close_date", label: "Cierre" },
        { key: "init_ticket_id", label: "Fol. Inicial" },
        { key: "end_ticket_id", label: "Fol. Final" },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getSucursales()
    //this.getActualTurnos();
  },
  created() { },
  methods: {
    moneyFormatter(value) {
      return "$" + String(parseFloat(value).toFixed(2))
    },
    nextInput(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      console.log(evt);
      var inputs = document.querySelectorAll(
        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      );
      for (var i = 0; i < inputs.length; i++) {
        if (
          document.activeElement.id == inputs[i].id &&
          i + 1 < inputs.length
        ) {
          inputs[i + 1].focus();
          break;
        }
      }
    },
    getSucursales() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursales = response.data;
        })
        .catch(function (r) {
          that.makeToast("danger", "Error", r.response.data);
        });
    },
    getActualTurnos() {
      let that = this;
      this.selectedCheck=[]
      axios
        .get("/shifts?sucursal_id=" + this.sucursal_id + "&initial_date=" + this.initial_date + "&end_date=" + this.end_date)
        .then(function (response) {
          that.turnos = response.data;
        })
        .catch(function (r) {
          that.makeToast("danger", "Error", r.response.data);
        });
    },
    printShifts() {
      let that = this;
      if (this.selectedCheck.length < 1) {
        that.makeToast("danger", "Error", "Selecciona al menos un turno");
        return
      }
      axios
        .post("/shift/cut/print", { shifts: this.selectedCheck, printer_id: 0 })
        .then(function (response) {
          console.log(response.data);
          that.makeToast("success", "Impreso", "Reporte de venta impreso");
        })
        .catch(function (r) {
          that.makeToast("danger", "Error", r.response.data);
        });
      if (this.sendEmail) {
        that.makeToast("success", "Enviando.....", "Enviando correos");
        axios
          .post("/shift/cut/excel", {
            shifts: this.selectedCheck,
            send_mail: true,
          })
          .then(function (response) {
            console.log(response.data);
            that.makeToast("success", "Correos Enviados", "Enviados con exito");
          })
          .catch(function (r) {
            that.makeToast("danger", "Error", r.response.data);
          });
      }
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
        appendToast: true,
        autoHideDelay: 10000,
      });
    },
  },
};
</script>
