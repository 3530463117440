<template>
  <div>
    <b-button style="primary" variant="primary" @click="openModal">Ver en Pantalla</b-button>
    <b-modal modal-class="full-width-modal" no-fade size="xl" no-close-on-backdrop hide-footer id="modal-infoturnos"
      title="INFORMACION DE TURNOS">
      <b-overlay :show="loading" rounded="sm">
        <template>
          <b-row>
            <b-col>
              <h4>Turnos: {{ shifts }} {{ sucursal_id }}</h4>
              <b-table responsive :items="shiftInfo.shift" :fields="turnosFields" striped small></b-table>
            </b-col>
          </b-row>
          <b-row> 
            <b-col>
              <b-alert variant="success" show>
                <h4>Venta Total: ${{ shiftInfo.sales_total.toFixed(2) }}</h4>
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <h4>Resumen de Faltante/Sobrante</h4>
                    <b-alert :show="balance_cash != 0" variant="danger">Tienes un {{ (balance_cash > 0 ? "sobrante" :
                      "faltante") }}
                      en efectivo de: {{ moneyFormatter(Math.abs(balance_cash)) }}</b-alert>
                    <b-alert :show="balance != 0 && Math.abs(balance.toFixed(2)) != Math.abs(balance_cash.toFixed(2))"
                      variant="warning">Tienes un {{ (balance > 0 ? "sobrante" : "faltante") }} global en formas de pago
                      de:
                      {{ moneyFormatter(Math.abs(balance)) }}</b-alert>
                    <table class="table table-sm w-auto">
                      <tbody>
                        <tr>
                          <td class="text-right">+TEORICO:</td>
                          <td class="text-left">{{ moneyFormatter(shift_total_available) }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">-DECLARADO:</td>
                          <td class="text-left">{{ moneyFormatter(shift_total_declared) }}</td>
                        </tr>
                        <tr>
                          <th class="text-right">Balance:</th>
                          <th class="text-left">{{ moneyFormatter(balance) }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          
            <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <h4>Ventas por area:</h4>
                    <b-table table-class="w-auto" responsive :items="shiftInfo.sales_by_restaurant_area" :fields="salesRestAreaFields"
                      striped small>
                      <template #cell(avg_sales_area)="data">
                        {{ moneyFormatter(data.item.total_with_discount / data.item.id) }}
                      </template>
                      <template #cell(avg_sales_comensal)="data">
                        {{ moneyFormatter(data.item.total_with_discount / data.item.persons) }}
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <h4>Ventas por metodo de pago:</h4>
                    <b-table table-class="w-auto" responsive :items="shiftInfo.sales_by_payment_method" :fields="salesPaymethodFields"
                      striped small></b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          
          
            <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <h4>Corte de Valores</h4>
                    <table class="table table-sm w-auto">
                      <tbody>
                        <tr>
                          <td class="text-right">+Efectivo Inicial:</td>
                          <td class="text-left">{{ moneyFormatter(shiftInfo.initial_cash) }}</td>
                        </tr>
                        <tr v-for="paym in shiftInfo.sales_by_payment_method" :key="paym.payment_method.id">
                          <td class="text-right">+{{ paym.payment_method.name }}</td>
                          <td class="text-left">{{ moneyFormatter(paym.payment_amount + paym.tip_amount) }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">-Retiros:</td>
                          <td class="text-left">{{ moneyFormatter(shiftInfo.withdrawals_cash_amount) }}</td>
                        </tr>
                        <tr>
                          <td class="text-right">+Depositos:</td>
                          <td class="text-left">{{ moneyFormatter(shiftInfo.deposit_cash_amount) }}</td>
                        </tr>
                        <tr>
                          <th class="text-right">TOTAL:</th>
                          <th class="text-left">{{ moneyFormatter(shift_total_available) }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>

              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <h4>Declaracion de cajero:</h4>
                    <b-table table-class="w-auto" responsive :items="shiftInfo.declared" :fields="declaredFields" striped small>
                    </b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <h4>Retiros: {{ moneyFormatter(shiftInfo.withdrawals_cash_amount) }}</h4>
                    <b-table table-class="w-auto" responsive :items="shiftInfo.withdrawals_cash" :fields="paydeskMovFields" striped
                      small></b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <b-row>
                  <b-col>
                    <h4>Ingresos: {{ moneyFormatter(shiftInfo.deposit_cash_amount) }}</h4>
                    <b-table table-class="w-auto" responsive :items="shiftInfo.deposit_cash" :fields="paydeskMovFields" striped
                      small></b-table>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <!-- <b-card>
          <b-row>
            <b-col>
              <h4>Descuentos:</h4>
              <b-table responsive :items="shiftDiscounts" :fields="discountsTableFields" striped small></b-table>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col>
              <h4>Popularidad:</h4>
              <b-button v-b-toggle.collapse-popularidad variant="primary">Ver Popularidad</b-button>
              <b-collapse id="collapse-popularidad" class="mt-2">
                <b-card>
                  <download-csv :data="shiftPop" class="btn btn-primary" name="popularidad.csv" separator-excel
                    :advancedOptions="configJsonPopu">Descargar</download-csv>
                  <b-table responsive :items="shiftPop" :fields="popularidadFields" striped small></b-table>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col>
              <h4>Popularidad por Mesero:</h4>
              <b-button v-b-toggle.collapse-popularidadMesero variant="primary">Ver Popularidad</b-button>
              <b-collapse id="collapse-popularidadMesero" class="mt-2">
                <b-card>
                  <download-csv :data="shiftPopWait" class="btn btn-primary" name="popularidadPorMesero.csv"
                    separator-excel :advancedOptions="configJsonPopu">Descargar</download-csv>
                  <b-table responsive :items="shiftPopWait" :fields="popularidadByWaiterFields" striped small></b-table>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
        </b-card> 
        <b-card>
          <b-row>
            <b-col>
              <h4>Ventas por mesero:</h4>
              <b-table responsive :items="salesByWaiter" :fields="salesByWaiterFields" striped small>
              </b-table>
            </b-col>
          </b-row>
        </b-card> -->

          <b-row>
            <b-col>
              <b-button variant="danger" @click="closeModal">Cerrar</b-button>
            </b-col>
          </b-row>
        </template>
      </b-overlay>
    </b-modal>
  </div>
</template>

<style>
/* Custom CSS to make the modal full-width */
.full-width-modal .modal-dialog {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.full-width-modal .modal-content {
  width: 100vw;
  /* Full viewport width */
  /* Optional: Full viewport height */
  padding: 1rem;
}
</style>
<script>
import axios from "axios";
export default {
  components: {},
  name: "ModalShiftInfo",
  props: ["shifts", "sucursal_id"],
  data() {
    return {
      loading: false,
      configJsonPopu: {},
      shiftInfo: {
        shift: [],
        sales_by_payment_method: [],
        declared: [],
        initial_cash: 0,
        deposit_cash_amount: 0,
        withdrawals_cash_amount: 0,
        sales_total: 0
      },
      shiftPop: [],
      shiftPopWait: [],
      shiftDiscounts: [],
      salesByWaiter: [],
      salesByWaiterFields: [
        { key: "id", label: "ID" },
        { key: "name", label: "Mesero", stickyColumn: true },
        { key: "count_tickets", label: "# Cuentas", tdClass: 'text-right', thClass: 'text-right' },
        { key: "total_sales", label: "Venta", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "total_tip", label: "Propinas", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "avg_sales", label: "Ventas Promedio", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "comensales", label: "# Comens.", tdClass: 'text-right', thClass: 'text-right' },
        { key: "comensales_avg", label: "Prom x Comens.", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
      ],
      turnosFields: [
        { key: "id", label: "ID" },
        { key: "open_date", label: "Apertura" },
        { key: "close_date", label: "Cierre" },
        { key: "init_ticket_id", label: "Ticket Inicial" },
        { key: "end_ticket_id", label: "Ticket Final" },
      ],
      salesRestAreaFields: [
        { key: "restaurant_area.name", label: "Area" },
        { key: "total_with_discount", label: "Total", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "tip", label: "Propinas", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "id", label: "# Cuentas", tdClass: 'text-right', thClass: 'text-right' },
        { key: "persons", label: "# comensales", tdClass: 'text-right', thClass: 'text-right' },
        { key: "avg_sales_area", label: "cons. prom", tdClass: 'text-right', thClass: 'text-right' },
        { key: "avg_sales_comensal", label: "cons. prom. p/p", tdClass: 'text-right', thClass: 'text-right' },

      ],
      salesPaymethodFields: [
        { key: "payment_method.name", label: "Metodo", stickyColumn: true },
        { key: "payment_amount", label: "Total", sortable: true, formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "tip_amount", label: "Propinas", sortable: true, formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
      ],
      paydeskMovFields: [
        { key: "id", label: "ID" },
        { key: "paydesk_movement_type.description", label: "Concepto", stickyColumn: true },
        { key: "amount", label: "Monto", sortable: true, formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "ref1", label: "ref" },
        { key: "ref2", label: "ref" },
        { key: "ref3", label: "ref" },
      ],
      discountsTableFields: [
        { key: "quantity", label: "Cantidad" },
        { key: "total_amount", label: "Subtotal", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "discount_amount", label: "Monto Descuento", sortable: true, formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "total_with_discount", label: "Total con descuento", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "discount_type_id", label: "Id descuento" },
        { key: "discount_name", label: "Nombre_descuento" },
        { key: "discount_percentage", label: "Porcentaje" },
      ],
      popularidadFields: [
        { key: "id", label: "ID" },
        { key: "clave", label: "Clave" },
        { key: "ndescription", label: "Nombre", sortable: true, stickyColumn: true },

        { key: "group", label: "Id Grupo", sortable: true },
        { key: "group_name", label: "Grupo" },
        { key: "restaurant_area_id", label: "Id Area", sortable: true },
        { key: "restaurant_area_name", label: "Area" },

        { key: "product_price", label: "Precio", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "quantity", label: "Cantidad", sortable: true },
        { key: "list_price", label: "Precio Lista" },
        { key: "total_without_discount", label: "Tot. Sin desc", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "total_with_discount", label: "Tot. con desc", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
      ],
      popularidadByWaiterFields: [
        { key: "id", label: "ID" },
        { key: "clave", label: "Clave" },
        { key: "ndescription", label: "Nombre", sortable: true, stickyColumn: true },

        { key: "group", label: "Id Grupo", sortable: true },
        { key: "group_name", label: "Grupo" },
        { key: "restaurant_area_id", label: "Id Area", sortable: true },
        { key: "restaurant_area_name", label: "Area" },

        { key: "product_price", label: "Precio", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "quantity", label: "Cantidad", sortable: true },
        { key: "list_price", label: "Precio Lista", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "total_without_discount", label: "Tot. Sin desc", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "total_with_discount", label: "Tot. con desc", formatter: this.moneyFormatter, tdClass: 'text-right', thClass: 'text-right' },
        { key: "waiter_id", label: "ID Mesero", sortable: true },
        { key: "waiter_name", label: "Nombre Mesero", sortable: true },
      ],
      declaredFields: [
        { key: "payment_method.name", label: "Tipo", sortable: true, tdClass: 'text-right', thClass: 'text-right' },
        { key: "amount", label: "Monto", formatter: this.moneyFormatter, tdClass: 'text-left', thClass: 'text-left' },
      ],
    };
  },
  computed: {
    shift_total_available() {
      let x = this.shiftInfo.initial_cash + this.shiftInfo.sales_by_payment_method.reduce((res, el) => res + el.payment_amount + el.tip_amount, 0) + this.shiftInfo.deposit_cash_amount - this.shiftInfo.withdrawals_cash_amount
      return x
    },
    shift_total_declared() {
      return this.shiftInfo.declared.reduce((res, el) => res + el.amount, 0)
    },
    balance_cash() {
      let x = this.shiftInfo.initial_cash + this.shiftInfo.sales_by_payment_method.filter(x => x.payment_method_id == 1).reduce((res, el) => res + el.payment_amount + el.tip_amount, 0) + this.shiftInfo.deposit_cash_amount - this.shiftInfo.withdrawals_cash_amount
      let declared = this.shiftInfo.declared_cash
      return declared - x
    },
    balance() {
      return this.shift_total_available - this.shift_total_declared
    }
  },
  methods: {
    moneyFormatter(value) {
      return "$" + String(parseFloat(value).toFixed(2))
    },
    closeModal() {
      this.$bvModal.hide("modal-infoturnos");
    },
    openModal() {
      if (this.shifts.length < 1) {
        this.makeToast("danger", "Error", "debes seleccionar al menos un turno")
        return
      }
      this.$bvModal.show("modal-infoturnos");
      this.getShiftsCut();
      // this.getShiftsPopularidad();
      // this.getShiftsPopularidadGroupedByWaiters();
      // this.getDiscounts();
      // this.getPropinasTurno()
    },
    makeToast(variant = null, title = "", text = "") {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    getShiftsCut() {
      let that = this;
      this.loading = true
      axios
        .post("/shifts/cut", {
          shifts: this.shifts,
          sucursal_id: this.sucursal_id
        })
        .then(function (response) {
          that.shiftInfo = response.data;
          that.loading = false
        })
        .catch(function (err) {
          console.log(err);
          that.loading = false
          that.closeModal();
          that.makeToast("danger", "Error", err.response.data);
        });
    },
    getPropinasTurno() {
      let that = this;
      axios
        .post("/waiters/tip/shifts", { shifts: this.shifts })
        .then(function (response) {
          that.salesByWaiter = response.data;
        })
        .catch(function (r) {
          that.makeToast("danger", "Error", r.response.data);
        });
    },
    getShiftsPopularidad() {
      let that = this;
      axios
        .post("/popularidad/shifts", {
          shifts: this.shifts,
        })
        .then(function (response) {
          that.shiftPop = response.data;
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("danger", "Error", err.response.data);
        });
    },
    getShiftsPopularidadGroupedByWaiters() {
      let that = this;
      axios
        .post("/popularidad/shifts/waiter", {
          shifts: this.shifts,
        })
        .then(function (response) {
          that.shiftPopWait = response.data;
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("danger", "Error", err.response.data);
        });
    },
    getDiscounts() {
      let that = this;
      axios
        .post("/ticket/discounts/shift", {
          shifts: this.shifts,
        })
        .then(function (response) {
          that.shiftDiscounts = response.data;
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("danger", "Error", err.response.data);
        });
    },
  },
};
</script>
