import Vue from 'vue'
import VueRouter from 'vue-router'
import CuentasHistoricas from '../views/CuentasHistoricas.vue'
import Signin from '../views/Signin.vue'
import Product from '../views/Product.vue'
import Products from '../views/Products.vue'

import Dashboard from '../views/Dashboard.vue'

import userGroups from '../views/UserGroups.vue'
import UserGroup from '../views/UserGroup.vue'


import Discounts from '../views/Discounts.vue'
import Discount from '../views/Discount.vue'

import Paymentmethods from '../views/Paymentmethods.vue'
import Paymentmethod from '../views/Paymentmethod.vue'

import ReportDailySells from '../views/reports/ReportDailySells.vue'
import ReportDailySellsArea from '../views/reports/ReportDailySellsArea.vue'
import Signup from '../views/Signup.vue'
import Groups from '../views/Groups.vue'
import Group from '../views/Group.vue'
import Personalizations from '../views/Personalizations.vue'
import Personalization from '../views/Personalization.vue'
import Waiters from '../views/Waiters.vue'
import Waiter from '../views/Waiter.vue'
import Preparationarea from '../views/PrepArea.vue'
import Preparationareas from '../views/PrepAreas.vue'
import ReportsDate from '../views/reports/ReportsDate.vue'
import RappiProducts from '../views/RappiProducts.vue'
import RappiItem from '../views/RappiItem.vue'
import RappiCategories from '../views/RappiCategories.vue'
import RappiCategory from '../views/RappiCategory.vue'

import SupplyLists from '../views/SupplyLists.vue'
import SupplyList from '../views/SupplyList.vue'

import Supplycategory from '../views/Supplycategory.vue'
import Supplycategories from '../views/Supplycategories.vue'
import Supplies from '../views/Supplies.vue'
import Supply from '../views/Supply.vue'

import Facturas from '../views/Facturas.vue'
import Factura from '../views/Factura.vue'
import FacturaClientes from '../views/FacturaClientes.vue'

import Loyaltyprograms from '../views/Loyaltyprograms.vue'
import Loyaltyprogram from '../views/Loyaltyprogram.vue'

import Uber from '../views/Uber.vue'
import UberOverview from '../components/uber/UberOverview.vue'
import UberMenus from '../components/uber/UberMenus.vue'
import UberCategories  from '../components/uber/UberCategories.vue'
import UberItems from '../components/uber/UberItems.vue'
import UberPersonalizations from '../components/uber/UberPersonalizations.vue'
import UberMenuViewer from '../views/UberMenuViewer.vue'

import Rappi from '../views/Rappi.vue'
import RappiOverview from '../components/rappi/RappiOverview.vue'
import RappiItems from '../components/rappi/RappiItems.vue'
import RappiCategoriesN from '../components/rappi/RappiCategories.vue'
import RappiStores from '../views/RappiStores.vue'

import Sucursales from '../views/Sucursales.vue'
import Sucursal from '../views/Sucursal.vue'
import Popularidad from '../views/reports/Popularidad.vue'
import SelectTenant from '../views/SelectTenant.vue'

import Compras from '../views/Compras.vue'
import Compra from '../views/Compra.vue'

import Movimientos from '../views/Movimientos.vue'
import Movimiento from '../views/Movimiento.vue'
import Existencias from '../views/Existencias.vue'
import Providers from '../views/Providers.vue'
import PaydeskMovements from '../views/PaydeskMovements.vue'
import RestaurantAreas from '../views/RestaurantAreas.vue'
import ManufacturingOrder from '../views/ManufacturingOrder.vue'
import ManufacturingOrders from '../views/ManufacturingOrders.vue'
import InventoryAnalysis from '../views/InventoryAnalysis.vue'
import Supplies_boms from '../views/Supplies_boms.vue'
import Supply_bom from '../views/Supply_bom.vue'
import Turnos from '../views/Turnos.vue'

Vue.use(VueRouter)



const ifNotAuthenticated = (to, from, next) => {
  let localtime = localStorage.getItem('user-token-time')
  if(localtime==null){
    next()
  }else{
    let act=Math.floor(Date.now() / 1000)
    let end=Number(localtime)
    if(act>end){
      next()
    }else{
      next({ path: '/dashboard' })
    }
  }
}

const ifAuthenticated = (to, from, next) => {
  //let localtoken = localStorage.getItem('user-token')
  let localtime = localStorage.getItem('user-token-time')
  if(localtime==null){
    console.log("nav to root2")
    next({ path: '/' })
  }else{
    let act=Math.floor(Date.now() / 1000)
    let end=Number(localStorage.getItem('user-token-time'))
    if(act>end){
      next({ path: '/' })
    }else{
      next()
    }
  }
  
  

}


const routes = [
  {
    path: '/',
    name: 'Signin',
    component: Signin,
    beforeEnter: ifNotAuthenticated,
    meta: { requireAuth: false }
  }, 

  {
    path: '/turnos',
    name: 'turnos',
    component: Turnos,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/boms',
    name: 'supplies_boms',
    component: Supplies_boms,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/bom/:id?',
    name: 'bom',
    component: Supply_bom,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },




  {
    path: '/existencias',
    name: 'existencias',
    component: Existencias,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/stock/analyze',
    name: 'InventoryAnalysis',
    component: InventoryAnalysis,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/providers',
    name: 'providers',
    component: Providers,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/manufacturingorders',
    name: 'manufacturingorders',
    component: ManufacturingOrders,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/manufacturingorder/:id?',
    name: 'manufacturingorder',
    component: ManufacturingOrder,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/movimientos',
    name: 'movimientos',
    component: Movimientos,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/movimiento/:id?',
    name: 'movimiento',
    component: Movimiento,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/compras',
    name: 'compras',
    component: Compras,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/compra/:id?',
    name: 'compra',
    component: Compra,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },


  {
    path: '/selecttenant',
    name: 'SelectTenant',
    component: SelectTenant,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/report/popularidad',
    name: 'Popularidad',
    component: Popularidad,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/facturas',
    name: 'Facturas',
    component: Facturas,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/factura',
    name: 'Factura',
    component: Factura,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },

  {
    path: '/facturacion/clientes',
    name: 'FacturaClientes',
    component: FacturaClientes,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/group/:id?',
    name: 'Group',
    component: Group,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/userGroups',
    name: 'userGroups',
    component: userGroups,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/userGroup/:id?',
    name: 'UserGroup',
    component: UserGroup,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/personalizations',
    name: 'Personalizations',
    component: Personalizations,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/personalization/:id?',
    name: 'Personalization',
    component: Personalization,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },{
    path: '/waiters',
    name: 'Waiters',
    component: Waiters,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/waiter/:id?',
    name: 'Waiter',
    component: Waiter,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/uber/menu/viewer',
    component: UberMenuViewer,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/uber/:id?',
    component: Uber,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false },
    children: [
      { path: '',name: 'Uber', component: UberOverview},
      { path: 'menus', component: UberMenus},
      { path: 'categories', component: UberCategories},
      { path: 'items', component: UberItems},
      { path: 'personalizations', component: UberPersonalizations}

    ]
  },
  {
    path: '/rappi/stores',
    name: 'RappiStores',
    component: RappiStores,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/rappi/menu/:id?',
    component: Rappi,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false },
    children: [
      { path: '', component: RappiOverview},
      { path: 'categories', component: RappiCategoriesN},
      { path: 'items', component: RappiItems},
    ]
  },
  {
    path: '/sucursales',
    name: 'sucursales',
    component: Sucursales,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/sucursal/:id?',
    name: 'Sucursal',
    component: Sucursal,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/rappi/items',
    name: 'RappiProducts',
    component: RappiProducts,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/rappi/item/:id?',
    name: 'RappiItemById',
    component: RappiItem,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/rappi/categories',
    name: 'RappiCategories',
    component: RappiCategories,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/rappi/category/:id?',
    name: 'RappiCategoryById',
    component: RappiCategory,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/preparationarea/:id?',
    name: 'Preparationarea',
    component: Preparationarea,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/preparationarea',
    name: 'CreatePreparationarea',
    component: Preparationarea,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/preparationareas',
    name: 'Preparationareas',
    component: Preparationareas,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/product/:id?',
    name: 'Product',
    component: Product,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/product',
    name: 'CreateProduct',
    component: Product,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/report/daily',
    name: 'ReportDailySells',
    component: ReportDailySells,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/report/date',
    name: 'ReportsDate',
    component: ReportsDate,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },  
  {
    path: '/report/daily/area',
    name: 'ReportDailySellsArea',
    component: ReportDailySellsArea,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/ticket/query',
    name: 'CuentasHistoricas',
    component: CuentasHistoricas,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/discounts',
    name: 'Discounts',
    component: Discounts,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },
  {
    path: '/discount/:id?',
    name: 'Discount',
    component: Discount,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },

  {
    path: '/paydesk/movements',
    name: 'PaydeskMovements',
    component: PaydeskMovements,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/areas',
    name: 'RestaurantAreas',
    component: RestaurantAreas,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/paymentmethods',
    name: 'Paymentmethods',
    component: Paymentmethods,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },

  {
    path: '/paymentmethod/:id?',
    name: 'Paymentmethod',
    component: Paymentmethod,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }

  },


  {
    path: '/supplycategories',
    name: 'Supplycategories',
    component: Supplycategories,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/supplycategory/:id?',
    name: 'Supplycategory',
    component: Supplycategory,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/supply/lists',
    name: 'SupplyLists',
    component: SupplyLists,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/supply/list/:id?',
    name: 'SupplyList',
    component: SupplyList,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },

  {
    path: '/supplies',
    name: 'Supplies',
    component: Supplies,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/supply/:id?',
    name: 'Supply',
    component: Supply,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/loyaltyprograms',
    name: 'Loyaltyprograms',
    component: Loyaltyprograms,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  },
  {
    path: '/loyaltyprogram/:id?',
    name: 'Loyaltyprogram',
    component: Loyaltyprogram,
    beforeEnter: ifAuthenticated,
    meta: { requireAuth: false }
  }, 
]


const router = new VueRouter({
  mode: "history",
  routes
})
export default router
