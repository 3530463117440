<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1 v-if="actualProgram.id > 0">
            {{ this.actualProgram.name }}
          </h1>
          <h1 v-else>Crear Programa</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            {{ this.actualProgram.id + "-" + this.actualProgram.name }}<br>
            Puntos requeridos: {{ this.actualProgram.rewards.length > 0 ? this.actualProgram.rewards[0].required_points :
              ""
            }}<br>
            Codigo Promocion: {{ this.actualProgram.rules.length > 0 ? this.actualProgram.rules[0].code : "" }}<br>
            Beneficio: {{ this.beneficio }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-3">
          <h1>Códigos de lealtad</h1>
          <b-button variant="default" v-b-modal.modal-createCodes>Crear códigos</b-button>
          <download-csv :data="cards" class="btn btn-default" :name="'codigos' + this.actualProgram.name + '.csv'"
            separator-excel>Descargar</download-csv>
        </b-col>
      </b-row>
      <b-row>
                <b-col>
                    <b-form-group label="Buscar: "> 
                        <b-form-input
                        type="search"
                        v-model="search"
                        @keyup="page= 1"
                        debounce="500">
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
      <b-row>
        <b-col>

          <b-table :filter="search" small stacked="sm" striped hover :items="cards" :fields="cardFields" responsive :per-page="byPage"
            :current-page="page">
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(buttons)="data">
              <b-button size="sm" variant="success" @click="viewCodeConsumption(data.item.code)">Ver +</b-button>
              <b-button size="sm" variant="info" @click="editCode(data.item.id)">Editar</b-button>
            </template>
            <template #cell(status)="data">
              <b-badge pill v-if="data.item.status == 'ACTIVE'" variant="success">ACTIVO</b-badge>
              <b-badge pill v-else-if="data.item.status == 'INACTIVE'" variant="danger">INACTIVO</b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination v-model="page" :total-rows="cards.length" :per-page="byPage"
            aria-controls="my-table"></b-pagination>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="modal-createCodes" title="Crear Códigos" @close="clearCreateCards">
      <div v-if="!createCards.createdCards.length > 0">
        <b-row>
          <b-col>
            <p>Ingresa los siguientes datos para crear codigos promocionales.</p>
          </b-col>
        </b-row>
        <b-row cols-lg="2" cols-md="1" cols="1">
          <b-col>
            <b-form-group label="Puntos:" description="Ingresa los puntos a otorgar por cada codigo">
              <b-form-input v-model.number="createCards.points" type="number" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Fecha de expiracion:" description="Ingresa la fecha de expiración de los codigos">
              <base-input type="datetime-local" v-model="createCards.expiration_date"></base-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Cantidad:" description="Ingresa cuantos códigos deseas generar">
              <b-form-input v-model.number="createCards.quantity" type="number" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="success" @click="PostCreateCards">Crear</b-button>

          </b-col>
        </b-row>
      </div>


      <b-row v-if="createCards.createdCards.length > 0">
        <b-col>
          <download-csv :data="createCards.createdCards" class="btn btn-default"
            :name="'codigosCreados' + this.actualProgram.name + '.csv'" separator-excel>Descargar</download-csv>
        </b-col>
      </b-row>
      <b-row v-if="createCards.createdCards.length > 0">
        <b-col>
          <b-table :filter="search" stacked="sm" striped hover :items="createCards.createdCards" :fields="cardFields" responsive
            :per-page="byPage" :current-page="page">
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
    <b-modal centered ref="modal-updateCode" :title="'Editar Tarjeta ' + actualCode.code" size="xl"
      @close="closeEditCode">
      <b-card>
        <b-row>
          <b-col cols="3">
            <b-form-group label="ID">
              <b-form-input v-model="actualCode.id" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Código">
              <b-form-input v-model="actualCode.code" :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Nombre">
              <b-form-input v-model="actualCode.customer_name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Telefono">
              <b-form-input v-model="actualCode.customer_phone" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Email">
              <b-form-input v-model="actualCode.customer_email" ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Fecha de Creación:">
              <b-form-input v-model="actualCode.create_date" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Fecha de Expiración:">
              <base-input type="datetime-local" v-model="actualCode.expiration_date"></base-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Saldo Actual:">
              <b-form-input v-model="actualCode.points" disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button size="sm" @click="closeEditCode()" variant="danger">Cancelar</b-button>
            <b-button size="sm" @click="updateCard()" variant="success">Guardar</b-button>
          </b-col>
        </b-row>

      </b-card>
      <b-row>
        <b-col>
          <h1>Agregar/Restar Saldo</h1>
        </b-col>
      </b-row>
      <b-card class="mt-3">
        <b-row>
          <b-col>
            <h3>Agregar Saldo</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Saldo Actual:">
              <b-form-input v-model="actualCode.points" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Agregar">
              <b-form-input v-model.number="addPoints"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="d-flex align-items-center">
            <h3>Total: {{ actualCode.points + addPoints }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button size="sm" variant="success"  @click="addPointsServer">Agregar Puntos</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col>
            <h3>Restar Saldo</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Saldo Actual:">
              <b-form-input v-model="actualCode.points" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Restar">
              <b-form-input v-model.number="subtractPoints"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col class="d-flex align-items-center">
            <h3>Total: {{ actualCode.points - subtractPoints }}</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button size="sm" variant="success" @click="subtractPointsServer" :disabled="actualCode.points < subtractPoints">Restar Puntos</b-button>
          </b-col>
        </b-row>
      </b-card>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
    <b-modal centered ref="modal-showCodeConsumption" title="Consumos en tarjeta" size="xl" @close="closeCodeConsumption">
      <b-row>
        <b-col cols="12">
          <b-table small stacked="sm" striped hover :items="actualConsumption" :fields="actualConsumptionFields" responsive>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Loyaltyprogram",
  components: { Nav },
  data() {
    return {
      search:"",
      statusoptions: [
        { value: "ACTIVE", text: "ACTIVO" },
        { value: "INACTIVE", text: "INACTIVO" },
      ],
      show: false,
      byPage: 20,
      page: 1,
      addPoints: null,
      subtractPoints: null,
      cards: [],
      cardFields: [
        { key: "id", label: "ID", sortable: true },
        { key: "code", label: "Código", sortable: true },
        { key: "create_date", label: "Fecha de Creación", sortable: true },
        { key: "expiration_date", label: "Fecha de Expiración", sortable: true },
        { key: "points", label: "Puntos", sortable: true },
        { key: "customer_name", label: "Nombre", sortable: true },
        { key: "customer_phone", label: "Telefono", sortable: true },
        { key: "customer_email", label: "Correo", sortable: true },
        { key: "buttons", label: "", sortable: false },
      ],
      createCards: {
        quantity: 1,
        expiration_date: "",
        points: 1,
        createdCards: []
      },
      actualCode: {
        id: null,
        program_id: null,
        company_id: null,
        code: "",
        expiration_date: "",
        create_date: "",
        points: null,
        customer_name: null,
        customer_phone: null,
        customer_email: null
      },
      actualConsumption: [],
      actualConsumptionFields: [
        { key: "loyalty_card_code", label: "Codigo", sortable: false },
        { key: "sucursal_name", label: "Sucursal", sortable: false },
        { key: "ticket_id", label: "ID", sortable: true },
        { key: "date_open", label: "Fecha", sortable: true },
        { key: "total", label: "Total", sortable: true },
        { key: "discount_amount", label: "Descuento", sortable: true },
        { key: "total_with_discount", label: "Total con desc", sortable: true },
        { key: "product_name", label: "Nomb en ticket", sortable: false },
        { key: "loyalty_program_name", label: "Nombre del programa", sortable: false },
      ],
      actualProgram: {
        id: 0,
        max_usage: 0,
        program_type: "coupons",
        trigger: "with_code",
        name: "CORTESIA EN CONSUMO PRUEBA",
        active: true,
        limit_usage: false,
        portal_visible: false,
        create_date: "2023-03-08 03:26:45",
        discount_line_product_id: 2713,
        require_extra: false,
        extra_description: "",
        rewards: [],
        rules: []
      },
    };
  },
  beforeRouteUpdate(to, from) {
    console.log(to);
    console.log(from);
    this.initData();
  },
  mounted() {
    this.initData();
  },
  computed: {
    beneficio() {
      if (!this.actualProgram.rewards.length > 0) {
        return ""
      }
      let c = ""
      c += this.actualProgram.rewards[0].reward_type + " "
      c += this.actualProgram.rewards[0].discount + " "
      c += this.actualProgram.rewards[0].discount_mode + " "
      c += this.actualProgram.rewards[0].discount_applicability
      return c
    }
  },
  methods: {
    addPointsServer(){
      if (this.addPoints==null || this.addPoints<=0){
        this.$bvModal.msgBoxOk('Error! Ingresa una cantidad valida a sumar',{centered: true})
        return
      }
      let that = this;
      console.log("/loyalty/program/" + this.actualProgram.id + "/card/"+this.actualCode.id+"/addPoints/"+String( this.addPoints))
      axios
        .get("/loyalty/program/" + this.actualProgram.id + "/card/"+this.actualCode.id+"/addPoints/"+String(this.addPoints))
        .then(function () {
          that.editCode(that.actualCode.id)
          that.addPoints=null
          that.$bvModal.msgBoxOk('Listo!',{centered: true})
          that.getCards()

        })
        .catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    subtractPointsServer(){
      if (this.subtractPoints==null || this.subtractPoints<=0){
        this.$bvModal.msgBoxOk('Error! Ingresa una cantidad valida a restar',{centered: true})
        return
      }
      let that = this;
      axios
        .get("/loyalty/program/" + this.actualProgram.id + "/card/"+this.actualCode.id+"/subtractPoints/"+this.subtractPoints)
        .then(function () {
          that.subtractPoints=null
          that.editCode(that.actualCode.id)
          that.$bvModal.msgBoxOk('Listo!',{centered: true})
          that.getCards()
        })
        .catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    viewCodeConsumption(code) {
      let that = this;
      axios
        .get("/loyalty/card/" + code + "/use")
        .then(function (response) {
          that.actualConsumption = response.data;
          that.$refs['modal-showCodeConsumption'].show()
        })
        .catch(function (err) {
          console.log(err)
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    closeCodeConsumption() {
      this.$refs['modal-showCodeConsumption'].hide()
      this.actualConsumption = []
    },
    editCode(id) {
      console.log(id)
      let that = this;
      axios
        .get("/loyalty/program/" + this.actualProgram.id + "/card/" + id)
        .then(function (response) {
          that.actualCode = response.data;
          that.$refs['modal-updateCode'].show()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    closeEditCode() {
      this.$refs['modal-updateCode'].hide()
      this.actualCode = {
        id: null,
        program_id: null,
        company_id: null,
        code: "",
        expiration_date: "",
        create_date: "",
        points: null
      }
    },
    updateCard() {
      let that = this;
      axios
        .put("/loyalty/program/" + this.actualProgram.id + "/card/" + this.actualCode.id, this.actualCode)
        .then(function (response) {
          that.actualCode = response.data;
          that.makeToast("Exito", "exito al guardar", "success");
          that.getProgram();
          that.getCards()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    initData() {
      console.log("creando");
      console.log(this.$route.params.id);
      if (this.$route.params.id > 0) {
        console.log("producto no nuevo");
        this.actualProgram.id = this.$route.params.id;
        this.getProgram();
        this.getCards()
      }
      console.log("termina creacion");
    },
    PostCreateCards() {
      let that = this
      axios
        .post("loyalty/program/" + this.actualProgram.id + "/card?qty=" + this.createCards.quantity, { expiration_date: this.createCards.expiration_date, points: this.createCards.points })
        .then(function (response) {
          that.createCards.createdCards = response.data;
          that.getCards()
          that.makeToast("Exito", "exito al crear", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    clearCreateCards() {
      this.createCards = {
        quantity: 1,
        expiration_date: "",
        points: 1,
        createdCards: []
      }
    },
    getCards() {
      let that = this;
      axios
        .get("/loyalty/program/" + this.actualProgram.id + "/cards")
        .then(function (response) {
          that.cards = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getProgram() {
      let that = this;
      axios
        .get("/loyalty/program/" + this.actualProgram.id)
        .then(function (response) {
          that.actualProgram = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    updateProduct() {
      let that = this;
      if (this.actualProgram.id > 0) {
        axios
          .put("/supply/" + this.actualProgram.id, this.actualProgram)
          .then(function (response) {
            that.actualProgram = response.data;
            that.makeToast("Exito", "exito al guardar", "success");
            that.getProgram();
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        axios
          .post("/supply", this.actualProgram)
          .then(function (response) {
            that.makeToast("Exito", "exito al crear, redirigiendo", "success");
            console.log(response.data);
            that.$router.push({
              name: "Supply",
              params: { id: response.data.id },
            });
          })
          .catch(function (err) {
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },

    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
